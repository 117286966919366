@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotate-infinite {
  @apply animate-spin;
  animation: rotate 2s linear infinite;
}

::-webkit-scrollbar {
  width: 6px;
  transition: all 0.3s ease-in-out;
  appearance: none;
}

.ant-select-selection-item {
  font-weight: 600;
}

.ant-select:hover .ant-select-selector,
.ant-select-focused .ant-select-selector {
  border-color: rgba(128, 128, 128, 0.266) !important;
  box-shadow: none !important;
}

/* ::-webkit-scrollbar-thumb {
  background-color: #2b59fa;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #03259b;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: #03259b;
} */

.active-sidebar {
  background: linear-gradient(to right, #03259b, #2b59fa);
}

/* CustomSelect.css */
.text-primaryblue {
  @apply text-blue-500;
}

.text-secondaryslate {
  @apply text-gray-500;
}

.custom-select-container {
  @apply flex flex-col;
}

.label-container {
  @apply mb-2;
}

.text-label {
  @apply flex flex-col;
}

.custom-select .ant-select-selector {
  @apply bg-transparent border border-gray-300 rounded-lg;
  @apply transition duration-300;
}

.custom-select .ant-select-selector:hover {
  @apply border-blue-500;
}

.custom-select .ant-select-selection-item {
  @apply px-2.5;
}

.ant-input-group-addon {
  background-color: transparent !important;
}

.ql-container {
  min-height: 70vh;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: white;
}

.ql-toolbar {
  background: white;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom: none;
}

.iso-search-field {
  box-shadow: 0px 2px 8px -4px #1018280d;
  box-shadow: 0px 6px 15px -2px #a2b5da26;
}

.no-hover-dropdown {
  .ant-dropdown-menu-item {
    cursor: default;
    pointer-events: none;
  }
}

.risk-register-questionnaire-shadow {
  box-shadow: 0px 4px 8px -4px #10182808;
  box-shadow: 0px 8px 20px -5px #a2b5da40;
}
.risk-assessment-shadow {
  box-shadow: 0px 2px 8px -4px #1018280d;
  box-shadow: 0px 6px 15px -2px #a2b5da26;
}
.risk-assessment-boxes-shadow {
  box-shadow: 0px 4px 8px -4px #10182808;
  box-shadow: 0px 8px 20px -5px #a2b5da40;
}

.gradient {
  background-image: linear-gradient(
    to top,
    #c9d4fe,
    #d4ddfe,
    #dfe5ff,
    #eaeeff,
    #f5f7ff
  );
}
